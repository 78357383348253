//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import normalHead from '../../components/ishop/header.vue'
	import { mapMutations } from 'vuex'
	import { miceService } from '@/service/miceService'
	export default {
		name: 'chooseType',
		data() {
			return {
				headValue: {
					name: '首页',
					haveBack: true,
				},
				typeList:[]
			}
		},
		created(){
			this.loadConfig();
		},
		methods: {
			async loadConfig() {
				let params = {
					channel: sessionStorage.getItem('channel') || 'app',
					cityId: sessionStorage.getItem("pingRegionId")
				}
				let response = await miceService.getTenantConfig(params)
				this.typeList = response.content.config.recommendConfig; //外出用餐、外卖相关配置信息
				let config = response.content.config.recommendConfig; //外出用餐、外卖相关配置信息
				let shopParam = response.content.config.iShopParam; //医院附近餐厅条件
				let ishopMsg = response.content.config.ishopSubmitMsg; //提交推荐时提示语句
				let ishopRuleMsg = response.content.config.ishopRuleMsg; //医院上线基本规则
				sessionStorage.setItem("tenantConfig", JSON.stringify(config));
				sessionStorage.setItem("shopConfig", JSON.stringify(shopParam));
				sessionStorage.setItem("ishopMsg", JSON.stringify(ishopMsg));
				sessionStorage.setItem("ishopRuleMsg", JSON.stringify(ishopRuleMsg));
			},
			imgSrc(item){
				switch(item.itemType){
					case 5:
						return require('@/assets/icon_2.png');
						break;
					case 7:
						return require('@/assets/icon_3.png');
						break;
				}
			},
			next(item){
				sessionStorage.setItem('itemType',item.itemType);//5==外出用餐 、7==外卖茶歇
				sessionStorage.setItem('isNeedHospital',item.isNeedHospital ? true : false);//是否需要选择医院
				sessionStorage.removeItem('hospital');//切换品类，清空医院信息（每个品类都需要重新关联医院）
				sessionStorage.removeItem('hospitalId');//切换品类，清空医院信息
				if(item.isNeedHospital){
					this.$router.push({
						path:'/hospital'
					})
				}else{
					let routerPath = '';
					switch(item.itemType){
						case 5:
							routerPath = '/myShops';
							break;
						case 7:
							routerPath = '/myTakeaway';
							break;
					}
					this.$router.push({
						path: routerPath
					})
				}
			},
			jump() {
				this.$router.push('/myShops')
			},
			jump1() {
				this.$router.push('/myTakeaway')
			}
		},
		components: {
			normalHead
		}
	}
